// src/pages/SpaceDSA/SpaceDSA.jsx
import React from 'react';
import styles from './SpaceDSA.module.css';
import logo from '../../assets/spacedsa/logo.png';
import screenshot1 from '../../assets/spacedsa/1.png';
import screenshot2 from '../../assets/spacedsa/2.png';

function SpaceDSA({ userClass, userType }) {
  // Full dynamic copy
  const copyMapping = {
    individual: {
      developer: {
        title: "SpaceDSA: Nail Your Next Tech Interview",
        subtitle: "Targeted practice to perfect data structures and algorithms.",
        paragraph1:
          "As a developer, continuous learning is essential. SpaceDSA automatically schedules revisits to crucial data structures and algorithmic concepts so you never forget the foundations you’ve already mastered.",
        paragraph2:
          "Stop re-teaching yourself the same topics every time you start interview prep. With SpaceDSA, you can steadily enhance your problem-solving speed and keep your coding skills one step ahead."
      },
      student: {
        title: "SpaceDSA: Ace Your Exams and Interviews",
        subtitle: "Build a strong DSA foundation for academic and career success.",
        paragraph1:
          "SpaceDSA ensures you regularly revisit what you’ve learned, preventing last-minute cramming for finals, OAs, and tech interviews.",
        paragraph2:
          "Stay consistent in your growth, and walk into interviews with confidence—knowing your fundamentals are solid."
      },
      job: {
        title: "SpaceDSA: Nail Your Next Tech Interview",
        subtitle: "Targeted practice to perfect data structures and algorithms.",
        paragraph1:
          "Preparing for a new role requires disciplined review. SpaceDSA automates your study schedule, highlighting only the DSA topics you’re most likely to forget.",
        paragraph2:
          "Always stay interview-ready, and cut down on wasted time and mental fatigue. Whether it’s whiteboard challenges or timed coding tests, you’ll be ready to impress."
      }
    },
    enterprise: {
      student: {
        title: "SpaceDSA: Strengthen Your Students’ Career Outcomes",
        subtitle: "A turnkey solution for educational institutions.",
        paragraph1:
          "Enhancing post-graduation success is a top priority for any university or coding bootcamp. SpaceDSA helps your students master essential DSA concepts, improving their chances at landing quality internships and job placements.",
        paragraph2:
          "Automated spaced repition based study plans save instructors time and ensures learners retain critical knowledge. Monitor progress, identify skill gaps, and guide students toward real-world readiness."
      },
    }
  };
  
  

  const content = copyMapping[userClass][userType] || {};

  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <div className={styles.header}>
          <img src={logo} alt="SpaceDSA Logo" className={styles.logo} />
          <h1>{content.title || "SpaceDSA"}</h1>
          <p>{content.subtitle || "Study DSA effectively using spaced repetition."}</p>
        </div>
        <hr />
        <p>{content.paragraph1 || "SpaceDSA sets you on a spaced repetition routine, ensuring you consistently reinforce past topics."}</p>
        <p>{content.paragraph2 || "Whether prepping for interviews or sharpening your skills, SpaceDSA optimizes your study sessions."}</p>
        <br />
        <a
          href="https://chromewebstore.google.com/detail/spacedsa/phdohlijhchimahmdicclcimgdlcegdd?hl=en-US"
          className={styles.downloadButton}
        >
          Download &amp; Try for Free
        </a>
      </aside>

      {/* Right Content */}
      <section className={styles.editorSection}>
        <section className={styles.features}>
          <h2>Enhance Your Data Structure and Algorithm Retention</h2>
          <p>
            Track your problem-solving time and automatically schedule reviews on a spaced repetition based study plan. Only revisit what you’re about to forget and focus your sessions where they matter most.
          </p>
        </section>
        <div className={styles.screenshots}>
          <img src={screenshot1} alt="SpaceDSA Screenshot 1" className={styles.screenshot} />
          <img src={screenshot2} alt="SpaceDSA Screenshot 2" className={styles.screenshot} />
        </div>
        <div className={styles.disclaimer}>
          <p>Cuitt LLC</p>
          <p>Disclaimer: Leetcode is not affiliated with nor endorses SpaceDSA.</p>
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f57cfa10-fc42-4b3d-b335-ec8b09657249"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </section>
    </div>
  );
}

export default SpaceDSA;
