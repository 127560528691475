// src/pages/Home/Home.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';

import logo from '../../assets/cpi/logo.png';

// Import the app components
import ClickCV from '../ClickCV/ClickCV';
import SpaceDSA from '../SpaceDSA/SpaceDSA';
import PromptGruup from '../PromptGruup/PromptGruup';

// Define the two tiers of user selection
const userClasses = [
  { id: 'individual', label: 'Individual' },
  { id: 'enterprise', label: 'Enterprise' },
];

const userTypes = [
  { id: 'developer', label: 'Developer' },
  { id: 'prompt', label: 'Prompt Engineer' },
  { id: 'student', label: 'Student' },
  { id: 'job', label: 'Job Seeker' },
];

// Tailored short tab descriptions for each app
const clickCvCopy = {
  individual: {
    developer: "Instantly generate tailored resumes every time you click apply.",
    prompt: "Instantly generate tailored resumes every time you click apply.",
    student: "Instantly generate tailored resumes every time you click apply.",
    job: "Instantly generate tailored resumes every time you click apply."
  },
  enterprise: {
    developer: "", // Not applicable
    prompt: "",    // Not applicable
    student: "Empower students to instantly tailor resumes that boost career outcomes.",
    job: "Boost career outcomes with resumes tailored for each job application."
  }
};

const spacedsaCopy = {
  individual: {
    developer: "Ace your tech interviews with cognitive science based study programs.",
    prompt: "",  // Not applicable
    student: "Ace your tech interviews with cognitive science based study programs.",
    job: "Ace your tech interviews with cognitive science based study programs."
  },
  enterprise: {
    developer: "",  // Not applicable
    prompt: "",     // Not applicable
    student: "Boost student outcomes with cognitive science based study programs for tech interviews.",
    job: ""         // Not applicable
  }
};

const promptGruupCopy = {
  individual: {
    developer: "Rapid and intuitive LLM prompt chaining for developers.",
    prompt: "Rapidly craft, test, and refine advanced AI prompts.",
    student: "", // Not applicable
    job: "",     // Not applicable,
  },
  enterprise: {
    developer: "Rapidly craft, test, and refine advanced AI prompts for development teams.",
    prompt: "Rapidly craft, test, and refine advanced AI prompts.",
    student: "", // Not applicable
    job: "",     // Not applicable
  }
};

function Home() {
  // Track which user class and user type are selected
  const [activeClass, setActiveClass] = useState(userClasses[0].id);
  const [activeType, setActiveType] = useState(userTypes[0].id);

  // Track which app/tab is *selected* versus which is actually displayed
  const [selectedApp, setSelectedApp] = useState("clickcv");
  const [displayedApp, setDisplayedApp] = useState("clickcv");

  // Manage fade-out/in states
  const [isFadingOut, setIsFadingOut] = useState(false);

  // Build available apps array based on selected userClass/userType
  const availableApps = [];

  // ClickCV is always available if copy is not empty
  if (clickCvCopy[activeClass][activeType]) {
    availableApps.push({
      id: "clickcv",
      name: "ClickCV",
      description: clickCvCopy[activeClass][activeType],
    });
  }

  // Add SpaceDSA if there's a non-empty copy
  if (spacedsaCopy[activeClass][activeType]) {
    availableApps.push({
      id: "spacedsa",
      name: "SpaceDSA",
      description: spacedsaCopy[activeClass][activeType],
    });
  }

  // Add PromptGruup if there's a non-empty copy
  if (promptGruupCopy[activeClass][activeType]) {
    availableApps.push({
      id: "promptgruup",
      name: "PromptGruup",
      description: promptGruupCopy[activeClass][activeType],
    });
  }

  // If the currently selected app is not in availableApps, reset it
  useEffect(() => {
    if (!availableApps.find((app) => app.id === selectedApp)) {
      // Fallback to the first available
      if (availableApps.length > 0) {
        setSelectedApp(availableApps[0].id);
        setDisplayedApp(availableApps[0].id);
      }
    }
  }, [activeClass, activeType, selectedApp, availableApps]);

  // Handle switching tabs with a fade-out then fade-in
  const handleAppTabClick = (id) => {
    if (id !== selectedApp) {
      setSelectedApp(id);
      // Start fade-out
      setIsFadingOut(true);

      // After fade-out completes (300ms), switch displayed tab and fade in
      setTimeout(() => {
        setDisplayedApp(id);
        setIsFadingOut(false);
      }, 300);
    }
  };

  // Render whichever app is currently displayed
  const renderDisplayedApp = () => {
    switch (displayedApp) {
      case "clickcv":
        return <ClickCV userClass={activeClass} userType={activeType} />;
      case "spacedsa":
        return <SpaceDSA userClass={activeClass} userType={activeType} />;
      case "promptgruup":
        return <PromptGruup userClass={activeClass} userType={activeType} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.home}>
      {/* HERO SECTION */}
      <header className={styles.hero}>
        <div className={styles.logo}>
          <img src={logo} alt="Castle Point Innovations Logo" className={styles.logoImg} />
        </div>
        <h1>Powerful Tools to Accelerate Your Productivity and Career</h1>
        <h2>
          Streamline your workflows, master new skills, and land more opportunities—faster than ever.
        </h2>
      </header>

      {/* WHO ARE YOU SELECTOR TITLE */}
      <div className={styles.selectorTitle}></div>
      <h1>Who are you?</h1>
      {/* TWO-LEVEL SELECTORS */}
      <div className={styles.selectors}>
        {/* User Class Selector */}
        <div className={styles.selectorGroup}>
          {userClasses.map((uc) => (
            <button
              key={uc.id}
              onClick={() => {
                setActiveClass(uc.id);
                // Reset userType to the first one whenever userClass changes
                setActiveType(userTypes[0].id);
              }}
              className={`${styles.selectorButton} ${
                activeClass === uc.id ? styles.activeSelector : ""
              }`}
            >
              {uc.label}
            </button>
          ))}
        </div>

        <div className={styles.selectorGroup}>
          {userTypes.map((ut) => {
            // When enterprise is selected, slightly adjust labeling
            let displayLabel = ut.label;
            if (activeClass === 'enterprise') {
              if (ut.id === 'student') {
                displayLabel = 'Educational Institution';
              } else if (ut.id === 'job') {
                displayLabel = 'Career Advisor';
              }
            }
            return (
              <button
                key={ut.id}
                onClick={() => setActiveType(ut.id)}
                className={`${styles.selectorButton} ${
                  activeType === ut.id ? styles.activeSelector : ""
                }`}
              >
                {displayLabel}
              </button>
            );
          })}
        </div>
      </div>

      {/* APP TABS */}
      <div className={styles.appTabs}>
        {availableApps.map((app) => (
          <button
            key={app.id}
            onClick={() => handleAppTabClick(app.id)}
            className={`${styles.appTabButton} ${
              selectedApp === app.id ? styles.activeAppTab : ""
            }`}
          >
            <div className={styles.appTabContent}>
              <span className={styles.appTabName}>{app.name}</span>
              <span className={styles.appTabDescription}>{app.description}</span>
            </div>
          </button>
        ))}
      </div>

      {/* ACTIVE APP CONTENT with fade out/in */}
      <div
        className={`${styles.appContent} ${
          isFadingOut ? styles.fadeOut : styles.fadeIn
        }`}
      >
        {renderDisplayedApp()}
      </div>
    </div>
  );
}

export default Home;
