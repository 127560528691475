// src/components/Footer/Footer.js
import React from 'react';
import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <p>{new Date().getFullYear()} Castle Point Innovations - Cuitt LLC</p>
      <p>For questions and pricing, email: contact@castlepointinnovations.com</p>
    </footer>
  );
}

export default Footer;
