// src/pages/ClickCV/ClickCV.jsx
import React from 'react';
import styles from './ClickCV.module.css';
import logo from '../../assets/clickcv/logo.png';
import feature1 from '../../assets/clickcv/feature1.png';
import feature2 from '../../assets/clickcv/feature2.png';

function ClickCV({ userClass, userType }) {
  // Full copy mapping for each userClass + userType.
  // For enterprise, developer and prompt are set to empty strings so they won’t render.
  const copyMapping = {
    individual: {
      developer: {
        title: "ClickCV: Instant, Tailored Tech Resumes",
        subtitle: "Highlight your coding expertise and standout projects.",
        paragraph1:
          "Tired of manually editing your resume for every new role? ClickCV identifies key job requirements and rearranges your experiences accordingly for every job you apply, saving you hours of busywork.",
        paragraph2:
          "Spend more time coding and less time wrestling with resume formats. Whether you’re building side projects or scaling production applications, let ClickCV showcase your tech journey with precision."
      },
      prompt: {
        title: "ClickCV: Instant Tailored Resumes",
        subtitle: "Put your AI-driven achievements front and center.",
        paragraph1:
          "Tired of manually editing your resume for every new role? ClickCV identifies key job requirements and rearranges your experiences accordingly for every job you apply, saving you hours of busywork.",
        paragraph2:
          "Demonstrate how you solve real-world problems with advanced language models. From brainstorming to deployment, let your resume speak volumes about your ingenuity. With a sharper focus on what hiring managers want, you can boost your interview rate and invest more energy into perfecting your job applications or skill set."
      },
      student: {
        title: "ClickCV: Instant Tailored Resumes",
        subtitle: "Turn academic successes into professional leverage.",
        paragraph1:
          "Tired of manually editing your resume for every new role? ClickCV identifies key job requirements and rearranges your experiences accordingly for every job you apply, saving you hours of busywork. Transform your projects, coursework, and extracurriculars into a polished resume that resonates with recruiters seeking fresh talent.",
        paragraph2:
          "By highlighting your most relevant achievements, you can break through the noise and stand out among other new graduates—showcasing your promise and potential."
      },
      job: {
        title: "ClickCV: Instant Tailored Resumes",
        subtitle: "Easily customize resumes for each opportunity.",
        paragraph1:
          "Tired of manually editing your resume for every new role? ClickCV identifies key job requirements and rearranges your experiences accordingly for every job you apply, saving you hours of busywork.",
        paragraph2:
          "With a sharper focus on what hiring managers want, you can boost your interview rate and invest more energy into perfecting your job applications or skill set."
      }
    },
    enterprise: {
      developer: {
        // Not currently applicable
        title: "",
        subtitle: "",
        paragraph1: "",
        paragraph2: ""
      },
      prompt: {
        // Not currently applicable
        title: "",
        subtitle: "",
        paragraph1: "",
        paragraph2: ""
      },
      student: {
        title: "ClickCV: Empower Your Students to Tailor Every Resume",
        subtitle: "Help students craft industry-ready resumes for every job opportunity they pursue.",
        paragraph1:
          "As an educational institution or career center, one of your biggest challenges is ensuring students present themselves effectively in an increasingly competitive job market. ClickCV provides your institution with a scalable solution that enables students to generate industry-aligned, tailored resumes for each job application—without requiring constant one-on-one coaching.",
        paragraph2:
          "By licensing ClickCV, your career services team can empower students with a self-service tool that dynamically structures resumes based on job descriptions, helping them land internships and full-time roles more effectively. Reduce the manual effort of resume formatting while increasing the volume of successful applications, ultimately enhancing your institution’s career placement outcomes."
      },
      job: {
        title: "ClickCV: Scale and Elevate Your Resume Services",
        subtitle: "Equip your clients with a powerful tool to optimize their resumes for every job application.",
        paragraph1:
          "As a career advisor, resume writer, or job counselor, your expertise helps clients craft compelling resumes. But after the initial consultation, clients often struggle to tailor their resumes for each specific job listing—leading to missed opportunities. ClickCV extends your impact beyond a single resume, allowing clients to continually refine and optimize their applications with your guidance.",
        paragraph2:
          "By offering ClickCV as part of your service package, you provide clients with a structured, automated resume-building tool that ensures their qualifications are always positioned for maximum impact. This enhances client success rates, saves you valuable time, and reinforces your reputation as a results-driven career service provider."
      }        
    }
  };
  
  

  // Get the content for the current user combination.
  const content = copyMapping[userClass][userType];

  // If no content is defined (for enterprise developer or prompt), return null.
  if (!content || !content.title) {
    return null;
  }

  return (
    <div className={styles.container}>
      {/* Left Sidebar */}
      <aside className={styles.sidebar}>
        <div className={styles.header}>
          <img src={logo} alt="ClickCV Logo" className={styles.logo} />
          <h1>{content.title}</h1>
          <p>{content.subtitle}</p>
          <hr />
        </div>
        <p>{content.paragraph1}</p>
        <p>{content.paragraph2}</p>

        <div className={styles.imageGallery}>
          {/* Optionally display specific images for each scenario */}
        </div>
        <br />
        <a
          href="https://chromewebstore.google.com/detail/clickcv/blndoopcfepmdfbbajpbldigolihligc?authuser=0&hl=en"
          className={styles.downloadButton}
        >
          Download &amp; Try for Free
        </a>
        <br />
        <a
          href="https://clickcv.app/"
          className={styles.downloadButton}
        >
          Go to Official Website
        </a>
      </aside>

      {/* Right Content */}
      <section className={styles.editorSection}>
        <h2>Show Recruiters You're the Perfect Fit</h2>
        <p>
          ClickCV instantly create ATS friendly, drag-and-drop resumes that best match any job you apply to.  Compatible with a variety of job boards.
        </p>
        <p>
          You provide the content! Pool all your qualifications, and let ClickCV do the rest. 
          Your sections and bullet points are automatically rearranged 
          to spotlight your most relevant experience.
        </p>

        <img
          src={feature1}
          alt={content.featureImageAlt}
          className={styles.featureImage}
        />

        <img src={feature2} alt={content.featureImageAlt} className={styles.featureImage} />

        <h2>Every Resume is an Opportunity</h2>
        <p>
          Ready to jump into a new role? 
          Let ClickCV handle the fine details so you can focus on the job search.
        </p>

        {/* Disclaimer */}
        <div className={styles.disclaimer}>
          <p>Cuitt LLC</p>
          <p>
            Disclaimer: None of the job boards depicted are affiliated with or endorse ClickCV.
            We do not collect or share the personal data from which your resumes are based upon.
          </p>
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f57cfa10-fc42-4b3d-b335-ec8b09657249"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </section>
    </div>
  );
}

export default ClickCV;
