import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PromptGruup.module.css';
import logo from '../../assets/promptgruup/logo.png';
import screen1 from '../../assets/promptgruup/screen1.png';
import screen2 from '../../assets/promptgruup/screen2.png';
import screen3 from '../../assets/promptgruup/screen3.png';
import screen4 from '../../assets/promptgruup/screen4.png';


function PromptGruup({ userClass, userType }) {
  // Mapping copy for each scenario.
  const copyMapping = {
    individual: {
    developer: {
        title: "PromptGruup: Optimize Your LLM Workflows",
        subtitle: "Build, test, and refine generative AI solutions—faster.",
        paragraph1:
        "Whether you're integrating LLMs into personal projects, freelance work, or AI-driven applications, structuring effective prompt chains can be a challenge. PromptGruup provides a node-based interface that lets you visually design, test, and refine workflows without losing track of what works.",
        paragraph2:
        "Move beyond scattered scripts and manual prompt tweaking. With built-in version control, structured API chaining, and response parsing, PromptGruup ensures your AI-driven applications work with efficiency and precision."
    },
    prompt: {
        title: "PromptGruup: Advanced Prompt Engineering Workflows",
        subtitle: "Turn your AI prompt ideas into powerful automation pipelines.",
        paragraph1:
        "Crafting high-performing prompts requires iteration and optimization. PromptGruup provides a structured, visual tool where you can design, test, and refine complex prompt workflows—all without jumping between messy code or disparate tools.",
        paragraph2:
        "Experiment with variations, integrate multiple models in a single flow, and track performance over time. Whether you're a solo AI researcher or building AI-powered tools, PromptGruup keeps you focused on results, not manual debugging."
    },
    student: {
        // Not currently applicable
        title: "",
        subtitle: "",
        paragraph1: "",
        paragraph2: ""
    },
    job: {
        // Not currently applicable
        title: "",
        subtitle: "",
        paragraph1: "",
        paragraph2: ""
    }
    },      
    enterprise: {
        developer: {
          title: "PromptGruup Enterprise: Optimize LLM API Workflows for Developers",
          subtitle: "Design, iterate, and deploy structured multi-step AI workflows effortlessly.",
          paragraph1: 
            "Scaling AI-driven applications isn’t just about the models—it’s about how effectively you structure and optimize LLM API interactions. PromptGruup Enterprise enables development teams to streamline the design, testing, and deployment of structured LLM workflows that seamlessly connect ChatGPT, Claude, and other models.",
          paragraph2: 
            "With real-time collaboration, version-controlled workflows, and multi-step API chaining, developers can eliminate redundant testing, maintain consistency across AI interactions, and accelerate time-to-production. Whether automating content generation, enhancing search retrieval, or integrating AI into business operations, PromptGruup ensures every LLM call is structured, efficient, and scalable."
        },
        prompt: {
          title: "PromptGruup Enterprise: Scalable Tools for Enterprise Prompt Engineers",
          subtitle: "Structure, refine, and optimize AI-driven text generation workflows.",
          paragraph1: 
            "Building reliable, scalable AI-powered workflows requires more than trial and error. PromptGruup Enterprise provides a structured system where prompt engineering teams can design, test, and iterate on complex multi-step interactions with LLM APIs—ensuring consistent, high-quality outputs across use cases.",
          paragraph2: 
            "Track changes, experiment with multi-node workflows, and integrate multiple LLM providers in a controlled, versioned environment. With centralized prompt management, your enterprise can drive efficiency, standardization, and innovation across all AI applications."
        },
        student: {
          title: "",
          subtitle: "",
          paragraph1: "",
          paragraph2: ""
        },
        job: {
          title: "",
          subtitle: "",
          paragraph1: "",
          paragraph2: ""
        }      
      }      
  };
  
  

  const content = copyMapping[userClass][userType] || {};

  return (
    <div className={styles.container}>
      {/* Left Sidebar */}
      <aside className={styles.sidebar}>
        <div className={styles.header}>
          <img src={logo} alt="PromptGruup Logo" className={styles.logo} />
          <h1>{content.title || "Prompt Chaining Simplified"}</h1>
          <p>
            {content.subtitle ||
              "Design, iterate, and share advanced LLM workflows in one place."}
            <hr />
          </p>
        </div>
        <p>{content.paragraph1}</p>
        <p>{content.paragraph2}</p>
        <br />
          <a
            href="https://promptgruup.com/"
            className={styles.downloadButton}
            >
                Learn More
            </a>
            <br />
            <a
            href="https://promptgruup.com/create-account"
            className={styles.downloadButton}
            >
                Create Account
            </a>
      </aside>

      {/* Right Content */}
      <section className={styles.editorSection}>
        <h2>Elevate Your Prompt Engineering</h2>
        <p>
          PromptGruup streamlines your workflow by letting you visualize,
          manage, and optimize your prompt chains for a diverse range of applications.
        </p>
        <p>
          Focus on innovating while our tool handles the tedious parts of prompt chaining.  Run messages across multiple LLMs with varying parameters at once, pass or parse responses between LLM API calls, and save templates to quickly return to configurations that have worked for you.
        </p>
        <img src={screen1} alt="PromptGruup Screenshot 1" className={styles.screenshot} />
        <h3>Features & Capabilities</h3>
        <ul>
          <li>Node based prompt chaining interface</li>
          <li>Secure API key management</li>
          <li>Version control for iterative development</li>
          <li>Real-time collaborative editing</li>
          <li>Export options in JSON and YAML formats</li>
        </ul>
        <div className={styles.screenshots}>
          <img src={screen2} alt="PromptGruup Screenshot 2" className={styles.screenshot} />
          <img src={screen3} alt="PromptGruup Screenshot 3" className={styles.screenshot} />
          <img src={screen4} alt="PromptGruup Screenshot 4" className={styles.screenshot} />
        </div>
        <h2>Ready to Transform Your Workflow?</h2>
        <p>
          Join the PromptGruup community and harness advanced prompt engineering
          to power your projects.
        </p>
      </section>
    </div>
  );
}

export default PromptGruup;
